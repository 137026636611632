import React from 'react'

import { globalClickHandler } from '@/helpers/globalClickHandler'
import {
  Body,
  Button,
  COLORS,
  Caption,
  CloudinaryImage,
  Footnote,
  Spacer,
  TitleLarge,
  TitleSmall,
  TitleXXLarge,
} from 'ethos-design-system'
import { EVENT_NAMES } from 'lib/@getethos/analytics/constants'

import { useNavigateToApp } from '@/hooks/useNavigateToApp'

import { cmsModuleAnalytics } from '../../../lib/@getethos/analytics/analyticsEvents'
import { transmitRiskToken } from '../../../lib/TransmitSecurity/riskToken'
import { HIGHLIGHT_MEDIA_TYPES } from '../../constants'
import northstarStyles from '../../styles/northstar.module.scss'
import Markdown, { MARKDOWN_NODE_RESTRICTION_SETS } from '../global/Markdown'
import textLinkStyles from '../global/TextLink.module.scss'
import VideoEmbed from '../global/VideoEmbed'
import styles from './Highlight.module.scss'

interface MultimediaSectionProps {
  mediaType: keyof typeof HIGHLIGHT_MEDIA_TYPES
  mediaPosition: keyof typeof MEDIA_POSITIONS
  displayMediaTitle: boolean
  displayMediaCaption: boolean
  mediaTitle: string
  youtubeId: string
  mediaCaption: string
  groupImage: string
  imageAlt: string
  mediaOptions: string
}

interface HighlightImageProps {
  groupImage: string
  imageAlt: string
  mediaOptions: string
}

interface HighlightCtaProps {
  ctaStyle: keyof typeof CTA_STYLES
  ctaLabel: string
  clickthroughUrl: string
}

interface HighlightHeadingProps {
  headingSize: keyof typeof HEADING_SIZES
  text: string
  enableH1: boolean
}

interface MarkdownSectionProps {
  displayHeading: boolean
  heading: string
  enableH1: boolean
  body: string
  eyebrow: string
  displaySubHeading: boolean
  subHeading: string
  displayEyebrow: boolean
  displayCta: boolean
  mediaPosition: keyof typeof MEDIA_POSITIONS
  headingSize: keyof typeof HEADING_SIZES
  highlightHeadingClassName: string
  highlightTextClassName: string
  displayMarkdownSpacer: boolean
  ctaStyle: keyof typeof CTA_STYLES
  clickthroughUrl: string
  ctaLabel: string
}

export interface HighlightProps {
  moduleData: {
    mediaType: keyof typeof HIGHLIGHT_MEDIA_TYPES
    mediaPosition: keyof typeof MEDIA_POSITIONS
    displayMediaTitle: boolean
    displayMediaCaption: boolean
    mediaTitle: string
    youtubeId: string
    mediaCaption: string
    groupImage: string
    imageAlt: string
    mediaOptions: string
    displayHeading: boolean
    heading: string
    enableH1: boolean
    displaySubHeading: boolean
    subHeading: string
    body: string
    eyebrow: string
    displayEyebrow: boolean
    displayCta: boolean
    headingSize: keyof typeof HEADING_SIZES
    ctaStyle: keyof typeof CTA_STYLES
    clickthroughUrl: string
    ctaLabel: string
    backgroundColor: string
    nslpStyle: string
    hideOrbMobile: boolean
    experimentKey?: string
    variant?: 'wills' | 'default' | 'willsShort'
  }
}

// Constants
const MEDIA_POSITIONS = {
  LEFT: 'left',
  RIGHT: 'right',
}

const HEADING_SIZES = {
  LARGE: 'TitleLarge',
  XLARGE: 'TitleXLarge',
  XXLARGE: 'TitleXXLarge',
  NSLP: 'TitleNSLP',
}

const CTA_STYLES = {
  TEXTLINK: 'textlink',
  FILLED: 'filled',
  OUTLINE: 'outline',
  SALAMANDER: 'salamander',
  CYPRESS: 'cypress',
  FOREST: 'forest',
}

/**
 * Highlight module wrapper around the Cloudinary Image component
 * @param groupImage
 * @param imageAlt
 * @param mediaOptions
 * @return {React.Component}
 * @constructor
 */
const HighlightImage = ({
  groupImage,
  imageAlt,
  mediaOptions,
}: HighlightImageProps) => (
  <figure>
    <CloudinaryImage
      publicId={groupImage}
      alt={imageAlt}
      crop={mediaOptions}
      width={[375, 460, 560, 560]}
      height={[225, 728, 780, 780]}
    />
  </figure>
)

const HighlightForegroundImage = ({
  groupImage,
  imageAlt,
  mediaOptions,
}: HighlightImageProps) => (
  <div className={styles.highlightForegroundImage}>
    <HighlightImage
      groupImage={groupImage}
      imageAlt={imageAlt}
      mediaOptions={mediaOptions}
    />
  </div>
)

// Responsive Spacer using media queries for top and bottom of Highlight module
const SpacerTop = () => (
  <div aria-hidden="true" className={styles.spacerMediaTop} />
)
const SpacerBottom = () => (
  <div aria-hidden="true" className={styles.spacerMediaBottom} />
)

// This spacing is included only if there is a caption
const CaptionSpacer = () => (
  <div aria-hidden="true" className={styles.spacerCaption} />
)

/**
 * Half-page media section containing an image with optional title and caption
 * @param mediaPosition
 * @param displayMediaTitle
 * @param mediaTitle
 * @param displayMediaCaption
 * @param mediaCaption
 * @param groupImage
 * @param imageAlt
 * @param mediaOptions
 * @param youtubeId
 * @return {React.Component}
 * @constructor
 */
const MultimediaSection = ({
  mediaType,
  mediaPosition,
  displayMediaTitle,
  mediaTitle,
  displayMediaCaption,
  mediaCaption,
  youtubeId,
  ...rest
}: MultimediaSectionProps) => {
  let media
  switch (mediaType) {
    case HIGHLIGHT_MEDIA_TYPES.VIDEO:
      media = (
        <VideoEmbed
          youtubeId={youtubeId}
          videoTitle={mediaTitle}
          extraClass={styles.videoEmbedWrapper}
        />
      )
      break
    case HIGHLIGHT_MEDIA_TYPES.FOREGROUND_IMAGE:
      media = <HighlightForegroundImage {...rest} />
      break
    default:
      media = <HighlightImage {...rest} />
      break
  }
  return (
    <div className={[styles.highlightMedia, styles[mediaPosition]].join(' ')}>
      <SpacerTop />
      {displayMediaTitle && (
        <div className={styles.mediaTitleHiddenPhoneAndTablet}>
          <TitleSmall.Sans.Regular400 centered>
            {mediaTitle}
          </TitleSmall.Sans.Regular400>
          <Spacer.H24 />
        </div>
      )}
      {media}

      {displayMediaCaption && (
        <>
          <CaptionSpacer />
          <Footnote.Regular400 centered color={COLORS.GRAY_SECONDARY}>
            {mediaCaption}
          </Footnote.Regular400>
        </>
      )}

      {/* no spacerbottom on media type foregroundImage */}
      {mediaType !== HIGHLIGHT_MEDIA_TYPES.FOREGROUND_IMAGE && <SpacerBottom />}
    </div>
  )
}

/**
 * Returns a CTA based on the ctaStyle passed in
 * @param ctaStyle
 * @param clickthroughUrl
 * @param ctaLabel
 * @return {React.Component}
 * @constructor
 */
const HighlightCta = ({
  ctaStyle,
  clickthroughUrl,
  ctaLabel,
}: HighlightCtaProps) => {
  const { TEXTLINK, FILLED, OUTLINE, SALAMANDER, CYPRESS, FOREST } = CTA_STYLES
  const { handleNavigateToApp } = useNavigateToApp()
  const url = clickthroughUrl

  const handleCtaClicked = () => {
    if (clickthroughUrl === '/app/') {
      transmitRiskToken()
    }
    cmsModuleAnalytics.ctaClicked({
      properties: {
        ctaLabel,
        clickthroughUrl,
        module: 'Highlight',
      },
    })
    handleNavigateToApp({}, false, false, false, url)
  }

  if (ctaStyle === TEXTLINK) {
    return (
      <>
        <Spacer.H40 />
        <div onClick={handleCtaClicked}>
          <div
            data-optimizely={Highlight.OptimizelySelectors.CTA}
            className={textLinkStyles.textLink}
          >
            {ctaLabel}
          </div>
        </div>
      </>
    )
  }

  let CtaButtonType, CtaButtonClass, CtaButtonFullWidth
  switch (ctaStyle) {
    case FILLED:
      CtaButtonType = Button.Medium.Black
      break
    case OUTLINE:
      CtaButtonType = Button.Medium.BlackOutline
      break
    case SALAMANDER:
      CtaButtonType = Button.Medium.Salamander
      break
    case FOREST:
      //@ts-ignore
      CtaButtonType = Button.Medium.Forest
      break
    case CYPRESS:
      CtaButtonType = Button.Medium.Cypress
      CtaButtonClass = styles['ctaContainer']
      CtaButtonFullWidth = true
      break
    default:
      CtaButtonType = Button.Medium.Black
  }

  const ctaPhoneOnly = (
    <div className={styles.ctaPhoneOnly}>
      <CtaButtonType fullWidth>{ctaLabel}</CtaButtonType>
    </div>
  )
  const ctaTabletAndUp = (
    <div
      className={[
        styles.ctaTabletAndUp,
        CtaButtonClass ? CtaButtonClass : '',
      ].join(' ')}
    >
      <CtaButtonType fullWidth={!!CtaButtonFullWidth}>{ctaLabel}</CtaButtonType>
    </div>
  )

  return (
    <>
      <Spacer.H40 />
      <div onClick={handleCtaClicked}>
        <div data-optimizely={Highlight.OptimizelySelectors.CTA}>
          {ctaPhoneOnly}
          {ctaTabletAndUp}
        </div>
      </div>
    </>
  )
}

const MarkdownSpacerBottom = () => (
  <div className={styles.spacerMarkdownBottom} />
)

/**
 * Component that returns the correct Title size depending on headingSize
 * @param headingSize
 * @param children
 * @return {React.Component}
 * @constructor
 */
const HighlightHeading = ({
  headingSize,
  text,
  enableH1,
}: HighlightHeadingProps) => {
  let Heading = TitleLarge.Serif.Regular400

  // TODO: The original Highlight module specified "TitleXXLarge" but the
  // editor module only had the option of "TitleXLarge". All pages currently
  // which specify TitleXLarge are really using TitleLarge. We should come back and
  // re-implement and clean up TitleXLarge in a follow up PR
  if (headingSize === HEADING_SIZES.XXLARGE)
    Heading = TitleXXLarge.Serif.Regular400

  const returnMarkdown = (
    <Markdown
      input={text}
      allowedMarkdownTypes={MARKDOWN_NODE_RESTRICTION_SETS.LINK_ONLY}
    />
  )

  const headingElement = enableH1 ? 'h1' : 'div'

  if (headingSize === HEADING_SIZES.NSLP)
    return (
      <div className={northstarStyles.northstarTitle}>{returnMarkdown}</div>
    )

  return <Heading element={headingElement}>{returnMarkdown}</Heading>
}

/**
 * Half-page section containing an Title, Markdown, Eyebrow and CTA
 * @param displayEyebrow
 * @param eyebrow
 * @param displayHeading
 * @param cta
 * @param heading
 * @param body
 * @param mediaPosition
 * @param HeadingComponent
 * @return {React.Component}
 * @constructor
 */
const MarkdownSection = ({
  displayEyebrow,
  eyebrow,
  displayHeading,
  heading,
  enableH1,
  displaySubHeading,
  subHeading,
  body,
  mediaPosition,
  displayCta,
  headingSize,
  highlightHeadingClassName = styles.highlightHeading,
  highlightTextClassName = styles.highlighText,
  displayMarkdownSpacer = true,
  ...rest
}: MarkdownSectionProps) => {
  return (
    <div className={[highlightTextClassName, styles[mediaPosition]].join(' ')}>
      <div className={styles.highlightTextInner}>
        {displayEyebrow && (
          <>
            <Caption.Medium500 allCaps>{eyebrow}</Caption.Medium500>
            <Spacer.H8 />
          </>
        )}
        {displayHeading && (
          <div className={highlightHeadingClassName}>
            <div data-optimizely={Highlight.OptimizelySelectors.HEADING}>
              <HighlightHeading
                headingSize={headingSize}
                text={heading}
                enableH1={enableH1}
              />
            </div>
          </div>
        )}
        {displaySubHeading && (
          <>
            <div className={styles.subHeading}>{subHeading}</div>
            <Spacer.H16 />
          </>
        )}
        <div data-optimizely={Highlight.OptimizelySelectors.BODY}>
          <Body.Regular400>
            <Markdown input={body} className={styles.highlightMarkdown} />
          </Body.Regular400>
        </div>
        {displayCta && <HighlightCta {...rest} />}
        {displayMarkdownSpacer && <MarkdownSpacerBottom />}
      </div>
    </div>
  )
}

export const Highlight = ({ moduleData }: HighlightProps) => {
  const {
    backgroundColor,
    mediaPosition,
    enableH1,
    nslpStyle,
    hideOrbMobile,
    ...rest
  } = moduleData

  const backgroundClasses = [
    styles.highlightBackground,
    styles[backgroundColor],
  ]

  const highlightHeadingClassName =
    moduleData.mediaType === HIGHLIGHT_MEDIA_TYPES.FOREGROUND_IMAGE
      ? styles.highlightForegroundHeading
      : styles.highlightHeading

  const highlightForegroundClassName =
    moduleData.mediaType === HIGHLIGHT_MEDIA_TYPES.FOREGROUND_IMAGE
      ? styles.highlightForegroundText
      : styles.highlightText

  if (nslpStyle) {
    const invertedMediaPosition =
      mediaPosition === MEDIA_POSITIONS.LEFT
        ? MEDIA_POSITIONS.RIGHT
        : MEDIA_POSITIONS.LEFT

    const greenOrb = backgroundColor === 'greenOrb'
    const orbClasses = [
      styles.orb,
      [
        mediaPosition === MEDIA_POSITIONS.LEFT
          ? styles.orbLeft
          : styles.orbRight,
      ],
      [hideOrbMobile ? styles.hideMobile : null],
    ]

    return (
      <div className={backgroundClasses.join(' ')}>
        {greenOrb && <div className={orbClasses.join(' ')} />}
        <div
          className={[
            styles.nslpHighlightWrapper,
            styles[invertedMediaPosition],
          ].join(' ')}
        >
          {/* .HighlightWrapper.left or .right specifies media position using flexbox */}
          <MultimediaSection
            mediaPosition={
              invertedMediaPosition as keyof typeof MEDIA_POSITIONS
            }
            {...rest}
          />
          <MarkdownSection
            mediaPosition={
              invertedMediaPosition as keyof typeof MEDIA_POSITIONS
            }
            enableH1={enableH1}
            highlightHeadingClassName={highlightHeadingClassName}
            highlightTextClassName={highlightForegroundClassName}
            displayMarkdownSpacer={
              moduleData.mediaType !== HIGHLIGHT_MEDIA_TYPES.FOREGROUND_IMAGE
            }
            {...rest}
          />
          <div className={styles.ctaPhoneOnly}>
            <Spacer.H24 />
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className={backgroundClasses.join(' ')}>
      <div
        className={[styles.highlightWrapper, styles[mediaPosition]].join(' ')}
      >
        <MarkdownSection
          mediaPosition={mediaPosition}
          enableH1={enableH1}
          highlightHeadingClassName={highlightHeadingClassName}
          highlightTextClassName={highlightForegroundClassName}
          displayMarkdownSpacer={
            moduleData.mediaType !== HIGHLIGHT_MEDIA_TYPES.FOREGROUND_IMAGE
          }
          {...rest}
        />
        <MultimediaSection mediaPosition={mediaPosition} {...rest} />
      </div>
    </div>
  )
}

Highlight.OptimizelySelectors = {
  CTA: 'Cta',
  BODY: 'Body',
  HEADING: 'Heading',
}

export default Highlight
